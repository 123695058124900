<template>
  <div class="" v-if="!isLoadingWebhookList">
    <form :autocomplete="false" @submit.prevent="submitTest()">
    <div class="">
      <div class="v16-section-description mb-4">Webhook Alert Testing</div>
      <b-row class="mt-3">
        <b-col cols="12" md="4"  >
         
          <div class="v16-section-heading">Choose an Alert to Simulate</div>
          </b-col>
          <div class="col-12 col-custom-md-7">
            <div class="v-16-input-control">
              <select id="alert-simulate" v-model="currentWebhook" class="">
                            <option :value="{}">Select</option>
                            <option :value="webhook" v-for="webhook in webhookList" :key="webhook.id">{{ webhook.event_title }}</option>
                        </select>
            </div>
            <div class="v16-section-description mt-1">This is a test webhook notification to ensure that our webhook integration is working correctly. </div>
           
          </div>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="12" md="4"  >
         
          <div class="v16-section-heading">Test URL</div>
          </b-col>
          <div class="col-12 col-custom-md-7">
            <div class="v-16-input-control">
              <input
                      type="text"
                      v-model="webhookUrl"
                      
                      class="mx-2 flex-grow-1 overflow-hidden" />
            </div>
            <span class="text-danger">{{ error.url }}</span>
           
          </div>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="12" md="4"  >
         
          <div class="v16-section-heading">Method</div>
          </b-col>
          <div class="col-12 col-custom-md-7">
            <div class="v-16-input-control">
              <input
                      type="text"
                     
                      style="pointer-events: none;"
                      readonly="true"
                      value="POST"
                      class="mx-2 flex-grow-1 overflow-hidden" />
            </div>
           
           
          </div>
      </b-row>
      
      <b-row class="mt-3" v-if="isCurrentWebhookPayload">
        <b-col cols="12" md="4"  >
         
         <div class="v16-section-heading">Sample Data</div>
         </b-col>
         <div class="col-12 col-custom-md-7">
          
                              <div class="card border-radius borderless mb-5" style="border:none;background-color: var(--grey-F8);">
                                <div class="card-body">
                                  <div class="d-flex flex-column  " style="gap:15px">
                                <div class="" v-for="(webhook, key) in currentWebhook.payload" :key="webhook.id">
                                    <label :for="key" class="text-capitalize font-color pr-2" style="text-align: right;">{{key.replaceAll('_',' ')}}:</label>
                                    <div class="v-16-input-control">
              <input
                      type="text"
                      
                      v-model="currentWebhook.payload[key]"
                      :placeholder="key.replaceAll('_',' ')"
                      :id="key"
                      class="mx-2 flex-grow-1 overflow-hidden" />
            </div>
                                    
                                </div>
                               </div>
                                </div>
                              </div>
                          
         </div>
      </b-row>
    </div>


    <FooterContent  >
<div class="d-flex align-items-center justify-content-end" >
  <Button>Test</Button>  

</div>
</FooterContent>
</form>
  </div>
  <!-- Skeleton -->

  <div class="" v-else>
    <p
      class="w-100"
      style="
        color: rgb(41, 41, 41);
        font-weight: 600;
        font-size: 24px;
      "
    >
      Webhook Alert Testing
    </p>

  
      <div class="">
      <div class="v16-section-description mb-4">Webhook Alert Testing</div>
      <b-row class="mt-3">
        <b-col cols="12" md="4"  >
         
          <div class="v16-section-heading">Choose an Alert to Simulate</div>
          </b-col>
          <div class="col-12 col-custom-md-7">
            <div class="">
              <div class="integrationImage bg-preload" style="height: 40px; width: 100%; border-radius: 14px">
            <div class="into-preload"></div>
          </div>
            </div>
            <div class="v16-section-description">This is a test webhook notification to ensure that our webhook integration is working correctly. </div>
           
          </div>
      </b-row>
      <b-row>
        <b-col cols="12" md="4"  >
         
          <div class="v16-section-heading">Test URL</div>
          </b-col>
          <div class="col-12 col-custom-md-7">
            <div class="integrationImage bg-preload" style="height: 40px; width: 100%; border-radius: 14px">
            <div class="into-preload"></div>
          </div>
          
           
          </div>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="12" md="4"  >
         
          <div class="v16-section-heading">Method</div>
          </b-col>
          <div class="col-12 col-custom-md-7">
            <div class="integrationImage bg-preload" style="height: 40px; width: 100%; border-radius: 14px">
            <div class="into-preload"></div>
          </div>
           
           
          </div>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="12" md="4"  >
         
         <div class="v16-section-heading">Sample Data</div>
         </b-col>
         <div class="col-12 col-custom-md-7">
          
                              <div class="card border-radius borderless" style="background-color: var(--grey-F8);">
                                <div class="card-body">
                                  <div class="d-flex flex-column  gap mt-2" style="gap:15px" v-for="index in 4" :key="index">
                                    <div class="integrationImage bg-preload" style="height: 40px; width: 100%; border-radius: 14px">
            <div class="into-preload"></div>
          </div>
                               </div>
                                </div>
                              </div>
                          
         </div>
      </b-row>
    </div>
    <!-- Old -->
     
  
  </div>

</template>

<script>
import Button from '../Layout/Button.vue';
import FooterContent from '../Layout/FooterContent.vue';
export default {
    props:{
        propWebhookList:Array
    },
    components:{
        Button,
        FooterContent
    },
    computed:{
      isCurrentWebhookPayload(){
        return Object.prototype.hasOwnProperty.call(this.currentWebhook, 'payload');
      }
    },
data(){
    return {
        webhookList:[],
        currentWebhook:{},
        webhookUrl:'',
        isLoadingWebhookList:false,
        error:{},
        isLoadingTestBtn:false
    }
},
mounted(){
    this.getWebhookList()
},
methods:{
    async submitTest(){
        if(this.checkValidity())
    return false
let  data ={url:this.webhookUrl,...this.currentWebhook}
data.payload= JSON.stringify(data.payload)
 this.isLoadingTestBtn=true;
        const response = await this.$store.dispatch('fetchData',{
        axiosMethod:'post',
        resource:'webhooks',
        method:'webhook_test',
        params:data

    })
    if(response&&response.status=='success'){
      this.toast('Webhook test has been successfully triggered!')
    }
    else
    this.toast('Something went wrong while testing webhook!')
     this.isLoadingTestBtn=false;
    },
    checkValidity(){
       
        this.error={}
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      if(this.webhookUrl=='')
        this.error.url='URL must be required.'
    else if (!urlRegex.test(this.webhookUrl)) {
 this.error.url ="Please enter valid URL for testing.";
    }
  if(Object.keys(this.currentWebhook).length === 0)
   this.error.webhook_type ="Please enter valid URL for testing.";

   if(Object.keys(this.error).length === 0)
    return false
    return true
    },
async getWebhookList(){
    
    this.isLoadingWebhookList=true;
    const response = await this.$store.dispatch('fetchData',{
        axiosMethod:'get',
        resource:'webhooks',
        method:'webhook_list_secret'

    })
    if(response&&response.status=='success'){
       this.webhookList=response.data.data
    }
     this.isLoadingWebhookList=false
},

toast(text){
      {
          this.$toasted.show(text, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
    },
    },
}
</script>

<style>
.font-color{
   color: var(--grey-85);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}
.font-weight-500{
    font-weight: 600;
}
.payment-alert{
    background-color: #FAFAFC;
    border-radius: 10px;
    padding: 10px;
}
.v-16-input-control select,.v-16-input-control select:active{
  flex:1;
  border:none;
  outline:none;
}
@media (min-width: 768px) {
    .col-custom-md-7 {
        flex: 0 0 54.333333%;
        max-width: 58.333333%;
    }
}
</style>